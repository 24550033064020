import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FaTwitter, FaFacebookF, FaInstagram, FaStrava } from "react-icons/fa"
import styled from "styled-components"

const StyledContainer = styled.div`
  background-color: #007bff;
  padding: 1em;
  margin-top: 2rem;
  height: 50px;
  display: flex;
  align-items: center;
`

const WhiteIcon = styled.a`
  color: snow;
`

const ContentDiv = styled.div`
  margin: 0 auto;
`

export default () => (
  <StyledContainer>
    <Container fluid>
      <footer>
        <ContentDiv>
          <Row className="text-center">
            <Col>
              <div>
                <WhiteIcon
                  href="https://twitter.com/lomondroads"
                  target="_blank"
                  title="Twitter"
                >
                  <FaTwitter />
                </WhiteIcon>
              </div>
            </Col>
            <Col>
              <div>
                <WhiteIcon
                  href="https://www.facebook.com/LomondRoadsCC/"
                  target="_blank"
                  title="Facebook"
                >
                  <FaFacebookF />
                </WhiteIcon>
              </div>
            </Col>
            <Col>
              <div>
                <WhiteIcon
                  href="https://www.instagram.com/lomondroadscc"
                  target="_blank"
                  title="Instagram"
                >
                  <FaInstagram />
                </WhiteIcon>
              </div>
            </Col>
            <Col>
              <div>
                <WhiteIcon
                  href="https://www.strava.com/clubs/lomond-roads-cc-4899"
                  target="_blank"
                  title="Strava"
                >
                  <FaStrava />
                </WhiteIcon>
              </div>
            </Col>
          </Row>
        </ContentDiv>
      </footer>
    </Container>
  </StyledContainer>
)
