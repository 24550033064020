import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import NavigationBar from "../components/NavigationBar"
import Jumbotron from "../components/Jumbotron"
import styled from "styled-components"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const header = "About Us"
const pageSummary = "Information about our cycling club."

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const ContentDiv = styled.div`
  flex-grow: 1;
  text-align: justify;
  text-justify: auto;
`

const AboutUsPage = () => (
  <StyledContainer>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content={pageSummary} />
      <title>Lomond Roads Cycling Club - About Us</title>
      <link rel="canonical" href="lomondroads.org/aboutUs" />
    </Helmet>
    <NavigationBar />
    <Jumbotron header={header} pageSummary={pageSummary} />
    <ContentDiv>
      <Container>
        <Row>
          <Col>
            <p>
              Lomond Roads Cycling Club are based in Clydebank just outside of
              Glasgow on the West Coast of Scotland. The club is primarly
              focused on road cycling however mountain biking is also catered
              for.
            </p>

            <p>
              Every Saturday morning the club has an organised run leaving from
              Kilbowie roundabout in Clydebank usually at 9:30am. The route and
              distance varies from week to week and also depending on the time
              of year meaning that throughout the year we cover many of the
              roads in the local area. In addition to Saturday rides, we also
              organise various midweek and evening rides for extra training. For
              further details on Sartuday rides see our{" "}
              <Link to="/clubRides">club rides page</Link>.
            </p>

            <p>
              Many members of the club also race and participate in a wide range
              of events including time trialing, road racing and cyclocross. As
              well as riding in competitive events, members of the club have
              also participated in various cyclosportive events and intend to
              continue to do so in the future.
            </p>

            <p>
              In addition to the Saturday rides, the club meet in the Double L
              Centre in Linnvale, near Clydebank every Wednesday night at
              7:30pm. This evening serves as a meeting point as well as circuit
              training sessions throughout the winter.
            </p>

            <p>
              If you are interested in finding out more about the club or are
              thinking about joining us on a Saturday run then please{" "}
              <Link to="/contactUs">contact us using the e-mail form</Link>.
              Alternatively come along for a chat with some club members on a
              Wednesday evening or join us on a Saturday ride.
            </p>

            <p>
              We strongly encourage and welcome new members for both the benefit
              of yourself as well as the club and the sport of cycling in
              general.
            </p>

            <p>
              The following documents are available for reference:
              <ul>
                <li>
                  <a href="/clubConstitution_2020.pdf " target="_blank">
                    Club Constitution
                  </a>
                </li>
                <li>
                  <a href="/Ridesafe.pdf" target="_blank">
                    Ridesafe Information Sheet
                  </a>
                </li>
                <li>
                  <a href="/socialMediaPolicy_2020.pdf" target="_blank">
                    Social Media Policy
                  </a>
                </li>
              </ul>
            </p>
          </Col>
        </Row>
      </Container>
    </ContentDiv>
    <Footer />
  </StyledContainer>
)

export default AboutUsPage
